$size-5: 1rem;
$size-6: .875rem;
$size-7: .75rem;

$white: #fff;
$heading-color: #303a40;
$sub-heading-color: #52636d;
$help-text-color: #818e9c;

$border-color: #818e9c;

$filling-order-background: #ced5d9;

$footer-color: #e8f7ff;
$footer-link-color: #fff;
$footer-background: #767f88;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  margin: 0;
  font-family: 'Helvetica Neue', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Droid Sans', Arial,
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  [lang="zh-hans"] {
    font-family: 'PingFang SC', 'Heiti SC', 'Microsoft YaHei', 'Heiti', Arial, sans-serif
  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.canvas {
  display: grid;
  width: 100%;
  background: $white;
  @media screen and (min-width: 800px) {
    grid-template-rows: 2fr 1fr;
    height: 100vh;
  }
}

.row {
  display: grid;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 800px) {
    &.first {
      grid-template-columns: repeat(5, 20%);
      grid-template-rows: repeat(2, 50%);

      .one {
        grid-column-start: 1;
        grid-column-end: 2;
      }

      .one, .four, .seven {
        grid-row-start: 1;
        grid-row-end: 3;
      }

      .two, .three {
        grid-column-start: 2;
        grid-column-end: 3;
      }

      .two, .five {
        grid-row-start: 1;
        grid-row-end: 2;
      }

      .three, .six {
        grid-row-start: 2;
        grid-row-end: 3;
        border-top: 0;
      }

      .four {
        grid-column-start: 3;
        grid-column-end: 4;
      }

      .five, .six {
        grid-column-start: 4;
        grid-column-end: 5;
      }

      .seven {
        grid-column-start: 5;
        grid-column-end: 6;
      }
    }

    &.second {
      grid-template-columns: repeat(2, 50%);

      .box {
        border-top: 0;
      }
    }
  }
}

.box {
  position: relative;
  border: 1px solid $border-color;
  padding: 0 1rem 1rem 1rem;
  @media screen and (max-width: 799px) {
    &:not(:first-child), &.eight {
      border-top: 0;
    }
    min-height: 160px;
  }
  @media screen and (min-width: 800px) {
    &:not(:first-child) {
      border-left: 0;
    }
  }

  h2, h3 {
    color: $heading-color;
    font-size: $size-5;
    text-transform: uppercase;
    letter-spacing: -0.02rem;
    font-weight: bold;
    line-height: 1.3;
    margin: 1rem 0 .75rem 0;

    [lang="zh-hans"] {
      display: block;
      letter-spacing: normal;
    }
  }

  h3 {
    font-size: $size-6;
    color: $sub-heading-color;
    margin: 1.75rem 0 .5rem 0;
  }

  .help {
    color: $help-text-color;
    font-size: $size-7;
    line-height: 1.5;
    font-style: italic;
  }

  .sub-section {
    min-height: 120px;
    @media screen and (min-width: 800px) {
      position: absolute;
      bottom: 0;
      height: 40%;
    }
  }

  .filling-order {
    position: absolute;
    bottom: 1rem;
    right: .75rem;
    width: 1.5rem;
    height: 1.5rem;
    text-align: center;
    font-size: $size-7;
    font-weight: bold;
    line-height: 1.5rem;
    border-radius: 4px;
    color: $white;
    background: $filling-order-background;
    cursor: default;
  }
}

.footer {
  width: 100%;
  padding: .75rem 1.5rem;
  color: $footer-color;
  background: $footer-background;
  font-size: $size-7;

  p:first-child {
    margin-bottom: .25rem;

    a {
      color: $footer-link-color;
      text-decoration: none;

      &:hover, &:focus {
        text-decoration: underline;
      }
    }
  }
}